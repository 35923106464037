/* eslint-disable curly */
/* eslint-disable arrow-body-style */
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "./auth.service";
import { GoogleTagManagerHandlerService } from "./google-tag-manager.service";
import * as Sentry from "@sentry/angular";
import { environment } from "../../environments/environment";
import { User, UserService } from "./authentication/user.service";
import { Db3DApiBackendClient } from "./api/db3d-api-backend-client.service";
import { IUserDetailsApiResponse, UsersDetailsFactory} from "../factories/user-details-factory";
import { RedirectByUserStatusService, UserStatusesRegistry } from "./user-status.service";
import { UserDetails } from "../data-models/manage-users/user-details.model";

@Injectable({ providedIn: "root" })
export class UserDetailsService {

  public currentUserDetails: BehaviorSubject<UserDetails> = new BehaviorSubject(null);
  private userDetailsFactory: UsersDetailsFactory = new UsersDetailsFactory();
  
  constructor(
    private authService: AuthService,
    private googleTagManagerHandlerService: GoogleTagManagerHandlerService,
    private userService: UserService,
    private db3DApiBackendClient: Db3DApiBackendClient,
    private redirectByUserStatusService: RedirectByUserStatusService
  ) {}

  public initialize(): void {
    this.subscribeToLoggedIn();
  }

  private subscribeToLoggedIn(): void {
    this.authService.loggedIn.subscribe((value) => this.onLoginStatusChanged(value));
  }

  private onLoginStatusChanged(isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.getCurrentUserDetails();
    } else {
      this.currentUserDetails.next(null);
    }
  }

  public getCurrentUserDetails(): void {

    this.db3DApiBackendClient.getUserDetails(environment.db3dBackendDomain).subscribe({
      next: (response: IUserDetailsApiResponse) => {
        const currentUser = this.userService.getCurrentUser();
        currentUser.analytics_attributes = response.analytics_attributes;

        if(this.arePermissionsOrStatusChanged(currentUser, response)) {
          currentUser.permissions = response.permissions;
          currentUser.status = response.status;
          localStorage.setItem(this.authService.userKey, JSON.stringify(currentUser));
        }

        const userDetails = this.userDetailsFactory.createFromBackendApi(response);
        this.currentUserDetails.next(userDetails);

        this.setUserDimensions(response.analytics_attributes, currentUser.uuid);

        if(response.status !== UserStatusesRegistry.active)
          this.redirectByUserStatusService.redirectUsersWithNotActiveStatus(response.status);
      },
      error: (error) => {
        // temrporay solution
        if (error.status === 403) {
          this.authService.logout();
        }
        Sentry.captureMessage("Could not get user details");
      }
    });
  }

  private arePermissionsOrStatusChanged(currentDataUser: User, newDataUser: IUserDetailsApiResponse): boolean {
    return (JSON.stringify(currentDataUser.permissions) !== JSON.stringify(newDataUser.permissions)) || (JSON.stringify(currentDataUser.status) !== JSON.stringify(newDataUser.status));
  }

  private setUserDimensions(dimensionsArray: Array<any>, _userUuid: string) {
    if (!dimensionsArray) return;

    const designerDimensions = {};

    dimensionsArray.forEach((dimension) => {
      designerDimensions[dimension.key] = dimension.value;
    });

    this.googleTagManagerHandlerService.sendEvent("setDesignerDimensions", designerDimensions);
  }

  public updateCurrentUser(newData) {
    this.currentUserDetails.next(newData);
  }
}
