/* eslint-disable arrow-body-style */
/* eslint-disable curly */
import {Component, OnInit, ViewChild} from "@angular/core";
import { NgForm, NgModel } from "@angular/forms";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { IntiaroAnalyticsClient } from "src/app/services/analytics/intiaro-analytics.service";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { User, UserService } from "src/app/services/authentication/user.service";
import { GoogleTagManagerHandlerService } from "src/app/services/google-tag-manager.service";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { environment } from "src/environments/environment";
import { RedirectService } from "src/app/services/redirect.service";
import { NavigationService } from "src/app/services/NavigationService.service";
import { UserDetailsService } from "src/app/services/user-details.service";
import { filter, take } from "rxjs";
import { RegistartionLastStepValidatorService } from "src/app/services/RegistartionLastStepValidator.service";
import { WhiteLabelSetupConfiguration } from "src/app/data-models/wl-account-setup/wl-config-data.model";
import { UserDetails } from "src/app/data-models/manage-users/user-details.model";

export type VerificationFormData = {
  full_name?: string;
  company?: string;
  wl_slug?: string;
  job_position?: string;
  portfolio_url?: string;
  phone_number?: string;
}

@Component({
  selector: "app-registration-last-step",
  templateUrl: "./registration-last-step.component.html",
  styleUrls: ["./registration-last-step.component.scss", "../authentication-module.scss"],
  providers:[RegistartionLastStepValidatorService]
})
export class RegistrationLastStepComponent implements OnInit {

  public user: User = new User();

  public processingCreateAccountRequest = false;

  public registrationAttempt = false;
  public registrationErrorsMessage: string = "";
  public registrationCode: any;
  private cookieName = "db3dregistrationCodeCoockie";

  @ViewChild("registrationForm") registrationForm: NgForm;

  public showContent: boolean = false;

  constructor(
    private googleTagManagerHandlerService: GoogleTagManagerHandlerService,
    private intiaroAnalyticsClient: IntiaroAnalyticsClient,
    private cookieService: CookieService,
    private userService: UserService,
    private whiteLabelConfigurationService: WhiteLabelConfigurationService,
    private db3DApiBackendClient: Db3DApiBackendClient,
    private router: Router,
    private redirectService: RedirectService,
    public navigationService: NavigationService,
    private userDetailsService: UserDetailsService,
    private registartionLastStepValidatorService: RegistartionLastStepValidatorService
  ) {}

  ngOnInit(): void {
    this.googleTagManagerHandlerService.sendEvent("verificationFormOpen");
    this.intiaroAnalyticsClient.sendEvent("verificationFormOpen");
    this.validateAndRedirect();
  }

  private validateAndRedirect(): void {
    this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.pipe(
      filter((wlSetupConfig: WhiteLabelSetupConfiguration) => wlSetupConfig.slug !== ""),
    ).subscribe((wlSetupConfig: WhiteLabelSetupConfiguration) => {

      this.userDetailsService.currentUserDetails.pipe(
        filter((currentUserDetails: UserDetails) => currentUserDetails !== null),
      ).subscribe((currentUserDetails: UserDetails) => {

        if (this.registartionLastStepValidatorService.validate(wlSetupConfig.twoStepRegistration, currentUserDetails)) {
          this.showContent = true;
        }else {
          this.router.navigate(["/"]);
        }
      });

    });
  }

  private get defaultAfterCompletePath(): string {
    return this.navigationService.verification;
  }

  public onSubmit(_event: Event): void {
    if (this.processingCreateAccountRequest) return;
    this.registrationAttempt = true;
    if (!this.registrationForm.valid) return;
    this.sendRegistrationForm();
  }

  private createHttpBody() : VerificationFormData {
    const body: VerificationFormData = {
      full_name: this.user.userName,
      company: this.user.company,
      job_position: this.user.jobPosition,
      wl_slug: this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.value.slug
    };
    if (this.user.portfolioUrl) body.portfolio_url = this.user.portfolioUrl;
    if (this.user.phone) body.phone_number = this.user.phone;
    return body;
  }

  private sendRegistrationForm(): void {

    this.processingCreateAccountRequest = true;

    this.db3DApiBackendClient.patchUserDetails(environment.db3dBackendDomain, this.userService.getCurrentUser()?.id, this.createHttpBody()).subscribe({
      next: () => {
        this.googleTagManagerHandlerService.sendEvent("verificationFormSubmitedSuccess");
        this.intiaroAnalyticsClient.sendEvent("verificationFormSubmitedSuccess");
        this.processingCreateAccountRequest = false;

        const nextUrl: string = this.redirectService.getRedirectQueryParams(window.location.search);

        this.userDetailsService.getCurrentUserDetails();
        this.userDetailsService.currentUserDetails.pipe(
          filter((currentUserDetails: UserDetails) => {
            if (currentUserDetails === null) return false;
            return currentUserDetails.name !== "";
          }),
          take(1)
        ).subscribe((_currentUserDetails: UserDetails) => {
          if(nextUrl) {
            this.redirectService.navigateByParam(nextUrl);
          } else {
            this.router.navigate([this.defaultAfterCompletePath]);
          }
        });

      },
      error:(errorResp) => {
        this.googleTagManagerHandlerService.sendEvent("verificationFormSubmitedError");
        this.intiaroAnalyticsClient.sendEvent("verificationFormSubmitedError");
        let registrationErrors: any= {};
        this.processingCreateAccountRequest = false;
        if (errorResp.error.errors) {
          registrationErrors = errorResp.error.errors.field_errors || {};
          registrationErrors.non_field_errors = errorResp.error.errors.non_field_errors || [];
        }

        if (registrationErrors?.registration_code) {
          this.registrationCode = null;
          this.cookieService.delete(this.cookieName);
        }

        if (errorResp.status < 500) {
          this.registrationErrorsMessage = "There was something wrong with registration data. Please correct the form and try again.";
        } else {
          this.registrationErrorsMessage = "Something went wrong. Please try again.";
        }
      },
    });
  }

  public checkInputValidity(inputModel: NgModel): boolean {
    return inputModel?.invalid && (inputModel?.dirty || inputModel?.touched || this.registrationAttempt);
  }

}
