import { Injectable } from "@angular/core";
import { UserDetails } from "../data-models/manage-users/user-details.model";

@Injectable({providedIn: "root"})
export class VerificationValidatorService {
  constructor() {}

  public validate(twoStepRegistration: boolean, currentUserDetails: UserDetails): boolean {
    if (twoStepRegistration) return !!(currentUserDetails.name || currentUserDetails.company || currentUserDetails.jobPosition);
    else return true;    
  }
}