import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserDetails } from "src/app/data-models/manage-users/user-details.model";
import { AuthService } from "src/app/services/auth.service";
import { UserDetailsService } from "src/app/services/user-details.service";
import { RedirectByUserStatusService, UserStatusesRegistry } from "src/app/services/user-status.service";

@Component({
  selector: "app-blocked",
  templateUrl: "./blocked.component.html",
  styleUrls: ["../authentication-module.scss"]
})
export class BlockedComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private userDetailsService: UserDetailsService,
    private router: Router,
    private redirectByUserStatusService: RedirectByUserStatusService
  ) {}

  ngOnInit(): void {
    this.validateStatus();
  }

  public logOut(): void {
    this.authService.logout(["/"]);
  }

  public validateStatus(): void {
    this.userDetailsService.currentUserDetails.subscribe({
      next: (user: UserDetails) => {
        if(user?.status !== UserStatusesRegistry.blocked)
          this.redirectByUserStatusService.redirectUsersWithNotActiveStatus(user?.status);
      },
      error: (err) => {
        this.router.navigate(["/"]);
      }
    });
  }

}
