import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { HttpClient, } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { GlobalSettingsService } from "src/app/services/settings/global-settings.service";
import { IPopupContent, PopupService } from "src/app/services/popup.service";
import { MyDesignSaverComponent } from "../my-design-saver/my-design-saver.component";
import { StateChange } from "ng-lazyload-image";
import { GoogleTagManagerHandlerService } from "src/app/services/google-tag-manager.service";
import { environment } from "../../../environments/environment";

import { UserDetailsService } from "../../services/user-details.service";
import * as Sentry from "@sentry/angular";
import { IntiaroAnalyticsClient, IntiaroAnalyticsConstants } from "../../services/analytics/intiaro-analytics.service";
import { UserService } from "../../services/authentication/user.service";
import { NavigationService } from "src/app/services/NavigationService.service";
declare var IntiaroAnalyticsManager: any;
declare var clearIntiaroInstances: any;

type FiltersAndSortingOption = {
  id: number;
  label: string;
  checked: boolean;
}

type Filter = {
  key: string;
  label: string;
  operator: string;
  options: FiltersAndSortingOption[];
  type: string;
}

type SearchAndFiltersEventBody = {
  search_value: string | null;
  filters: {[key: string]: any};
  sorting: string;
  total_number_products: number;
};

enum SortingMethod {
  asc = "ascending",
  desc = "descending",
  none = "none"
}

@Component({
  selector: "app-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.scss"],
  standalone: false,
})
export class ProductListComponent implements OnInit, OnDestroy, AfterViewChecked {
  static readonly STANDARD_PRODUCT_TYPE = "standard";
  static readonly CUSTOM_PRODUCT_TYPE = "custom";

  private queryParamsSub = Subscription.EMPTY;

  // Products
  public productType: string;
  public productList = [];
  public productDescription: any = {};
  private allowAds = false; // this is temporarly harcoded to false so that no "request product ads" are shown
  public showRequestFurnitureAd = false;
  public customConfigurationId: string;

  // Analytics
  public sessionUuid: any;

  public saveDesignGetConfigurationFailEventName = "saveDesignGetConfigurationFail";
  public saveDesignGetImageFailEventName = "saveDesignGetImageFail";

  // UI Controlls
  public filtersDefinition: any;
  public sortingDefinition: any;
  private selectedSorting = "";
  public pagination = {
    items_total: 0,
    page: 1,
    pages_total: 0,
    items_per_page: 15
  };
  public pageNumbers;
  public searchString = "";
  public enableInfiniteScroll = true;
  public userData;
  public isTouchedSearchInput = false;

  public environment = environment;

  @ViewChild("designSaver") designSaver: MyDesignSaverComponent;
  @ViewChild("unityDiv") unityDiv: ElementRef;
  @ViewChild("products") productsDiv: ElementRef;
  public downlodProductsTask: Observable<any>;
  public showFiltersMobile = false;
  private downloadProductsTaskSubscription: Subscription;
  public brandsFilters: any;
  private readonly brandFilterKey = "brand";
  public categoryFilters: any;
  private readonly categoriesFilterKey = "category";
  private readonly customConfigIdInitQueryParamKey = "custom-config-id";
  public customConfigId?: string;
  public getProductsInProgress: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalSettingsService: GlobalSettingsService,
    public authService: AuthService,
    public changeDetector: ChangeDetectorRef,
    public popupService: PopupService,
    public googleTagManagerHandlerService: GoogleTagManagerHandlerService,
    private intiaroAnalyticsClient: IntiaroAnalyticsClient,
    public userService: UserService,
    public userDetailsService: UserDetailsService,
    public navigationService: NavigationService,
    private db3DApiBackendClient: Db3DApiBackendClient
  ) { }

  ngAfterViewChecked(): void {
    if (this.productsDiv === undefined)
      return;

    const elements = this.productsDiv.nativeElement.children;
    if (elements.length > 0) {
      const height = 1.4 * 20 / Math.sqrt(elements.item(0).offsetWidth) * (elements.item(0).offsetWidth);
      const heightStyle = `${height}px`;

      for (let i = elements.length - 1; i >= 0; --i) {
        elements.item(i).style.height = heightStyle;

        const image = elements.item(i).querySelector(".image.product-image");
        if (image) {
          const imageHeight = image.offsetHeight;
          image.style.marginTop = `${(height-(139+imageHeight))/2}px`;
        }
      }
    }
    if (this.productList.length > 0) {
      const lastId = this.productList[this.productList.length - 1].id;
      for (let i = elements.length - 1 ; i >= 0; --i) {
        if (elements.item(i).getAttribute("data-id") === lastId.toString()) {
          if (elements.item(i).getBoundingClientRect().top < window.innerHeight)
            this.onScroll();

          break;
        }
      }
    }
  }

  public showSaveDesignPopup(): void {
    const options: IPopupContent = {
      ngDynamicComponent: {
        component: MyDesignSaverComponent,
        inputs:{
          popupService: this.popupService
        }
      }
    };
    setTimeout(() => {
      this.popupService.showPopup(options);
    }, 500);
  }

  ngOnInit() {
    this.initializeAnalytics();
    this.initializeSorting();
    this.initializeFilters();
    this.customConfigId = this.activatedRoute.snapshot.queryParamMap.get(this.customConfigIdInitQueryParamKey);
    this.subscribeToUserDetailsService();
  }

  private subscribeToUserDetailsService() {
    this.userDetailsService.currentUserDetails.subscribe((userData) => {
      this.userData = userData;
    });
  }

  onScroll() {
    if (this.enableInfiniteScroll && this.pagination.pages_total > this.pagination.page && this.downlodProductsTask === null)
      this.pageChanged(this.pagination.page + 1);

  }

  initializeAnalytics() {
    this.sessionUuid = IntiaroAnalyticsManager.instance.getCurrentSessionUuid();
  }

  private setFiltersDataObjects(filtersObjects: Array<any>) {
    this.brandsFilters = filtersObjects.find((filtersObject) => { return filtersObject.key === this.brandFilterKey; });
    this.categoryFilters = filtersObjects.find((filtersObject) => { return filtersObject.key === this.categoriesFilterKey; });
  }

  initializeFilters() {
    this.filtersDefinition = [];

    const filtersUrl = `${environment.db3dBackendDomain}/api/product-list/filters/`;

    this.http.get<any>(filtersUrl).subscribe(
      (response) => {
        this.filtersDefinition = response.filters;
        this.sortFiltersOptions(this.filtersDefinition);
        this.setFiltersDataObjects(this.filtersDefinition);
        this.subscribeToQueryParamsChange();
      },
      (error) => {
        console.log("Could not download filters definition. No filters are going to be available.");
      }
    );
  }

  sortFiltersOptions(filtersObjects: Array<any>) {
    filtersObjects.forEach((filter) => {
      filter.options = filter.options.sort((a, b) => {
        return (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0;
      });
    });
  }

  initializeSorting() {
    this.sortingDefinition = {
      type: "singular",
      options: [
        {label: "Name: ascending", id: "name"},
        {label: "Name: descending", id: "-name"}
      ],
      key: "sorting", label: "Sort by"
    };
  }

  async subscribeToQueryParamsChange() {
    this.queryParamsSub = this.activatedRoute
      .queryParams
      .subscribe(async(params) => {
        this.applyFromUrlParams(this.activatedRoute.queryParams);
        this.productType = params.type;
        if (!this.productType)
          this.productType = ProductListComponent.STANDARD_PRODUCT_TYPE;

        const newId = params.id;
        this.customConfigurationId = params.custom_configuration_id;

        if (newId === undefined) {
          clearIntiaroInstances();
          if (params.design_id)
            this.clearFilters(false);

          this.downloadProducts();

        } else if (newId !== undefined) {

          if (this.isProductTypeStandard())
            this.router.navigate([this.navigationService.pageNotFound]);
        }
      });
  }

  downloadProducts(resetList = true) {
    this.getProductsInProgress = false;
    if (this.downlodProductsTask) {
      this.downloadProductsTaskSubscription.unsubscribe();
      this.downlodProductsTask = null;
    }
    let qParams = this.buildPaginationParams(resetList);
    qParams += this.addFilterParams();
    qParams += this.addSortingParams();
    qParams += this.addSearchParams();
    if (resetList)
      this.productList = [];
    this.showRequestFurnitureAd = false;

    if (this.productType === "custom")
      this.downlodProductsTask = this.db3DApiBackendClient.getMyDesigns(environment.db3dBackendDomain, qParams);
    else
      this.downlodProductsTask = this.db3DApiBackendClient.getProductsList(environment.db3dBackendDomain, qParams);

    this.downloadProductsTaskSubscription = this.downlodProductsTask.subscribe(
      (response) => {
        this.getProductsInProgress = true;
        this.downlodProductsTask = null;
        this.buildList(response);

        const analyticsEventBody: SearchAndFiltersEventBody = {
          search_value: this.isTouchedSearchInput ? this.searchString : null,
          filters: this.getFiltersParameters(),
          sorting: this.getSortingParam(),
          total_number_products: response.pagination.items_total
        };
        if(this.shouldTrackSearchEvent(analyticsEventBody))
          this.intiaroAnalyticsClient.sendEvent(IntiaroAnalyticsConstants.AnalyticsAttrProductListSearchUsed, analyticsEventBody);

      },
      (error) => {
        // temrporay solution
        if (error.status === 403)
          this.authService.logout();

        Sentry.captureMessage("Could not download products");
      }
    );
  }

  private buildList(serverData) {
    if (this.enableInfiniteScroll)
      serverData.items.forEach((item) => { return this.productList.push(item); });
    else
      this.productList = serverData.items;


    if (this.allowAds) {
      if (this.productType === "standard") {
        if (this.productList.length === 0)
          this.showRequestFurnitureAd = true;

        this.productList = this.productList.filter((item) => { return item.id !== "requestProductAd"; });
        for (let i = 2; i < this.productList.length; i += 5 * 3)
          this.productList.splice(i, 0, {id: "requestProductAd"});

      }
    }

    this.pagination = serverData.pagination;
    this.setupPageNumbers(serverData.pagination);
  }

  goToProductDetails(product) {
    const analyticsContext = {
      mode: this.productType,
      productconfigurationid: this.isProductTypeStandard() ? product.id : product.custom_configuration_id,
      brand_name: product.brand_name,
      product_name: product.product_name,
      program_name: product.product_version_name
    };
    this.intiaroAnalyticsClient.sendEvent(IntiaroAnalyticsConstants.AnalyticsAttrProductTileClicked, analyticsContext);

    if(this.isProductTypeStandard())
      this.router.navigate([this.navigationService.productListWithId(product.id)], { queryParams: this.activatedRoute.snapshot.queryParams });
    else
      this.router.navigate([this.navigationService.productDesign(product.id)]);

  }

  isProductTypeStandard() {
    return this.productType === ProductListComponent.STANDARD_PRODUCT_TYPE;
  }

  isProductTypeCustom() {
    return this.productType === ProductListComponent.CUSTOM_PRODUCT_TYPE;
  }

  setupPageNumbers(pagination) {
    this.pageNumbers = new Array();
    this.pageNumbers.push(pagination.page);

    // reduce number of pages to display based on label lengts so they all fit on the narrow screen
    const pagesLimit = pagination.page <= 0 ? 9 :
      pagination.page <= 999 ? 7 :
        pagination.page <= 999999 ? 5 : 3;

    let i = 1;

    while (this.pageNumbers.length < pagesLimit && this.pageNumbers.length < pagination.pages_total) {
      if (pagination.page - i > 0)
        this.pageNumbers.unshift(pagination.page - i);


      if (pagination.page + i <= pagination.pages_total)
        this.pageNumbers.push(pagination.page + i);


      i++;
    }
  }

  onPaginationChange() {
    this.downloadProducts(false);
  }

  pageChanged(pageNumber) {
    this.pagination.page = pageNumber;
    this.googleTagManagerHandlerService.sendEvent("product_list_updated", {page_number: pageNumber, product_type: this.productType});
    this.downloadProducts(false);
  }

  onSortingChanged(event) {
    this.selectedSorting = "";
    event.options.forEach((option) => {
      if (option.checked)
        this.selectedSorting = option.id;

    });
    this.updateUrl();
  }

  buildPaginationParams(resetPagination = false) {
    let params = "";
    let sign = "?";
    if (resetPagination)
      this.pagination.page = 1;

    Object.keys(this.pagination).forEach((key) => {
      params += sign + key + "=" + this.pagination[key];
      sign = "&";
    });

    return params;
  }

  addSortingParams() {
    return this.selectedSorting.length === 0 ? "&order_by=list_index,-created" : `&order_by=${this.selectedSorting}`;
  }

  addSearchParams() {
    return this.searchString.length === 0 ? "" : `&search=${this.searchString}`;
  }

  addFilterParams(addKey = true) {
    let filtersString = "";

    if (!this.filtersDefinition)
      return filtersString;


    this.filtersDefinition.forEach((filter) => {
      let filtervalues = "";
      let operator = ",";

      if (filter.operator === "or")
        operator = "|";
      else if (filter.operator === "and")
        operator = ",";


      filter.options.forEach((option) => {

        if (option.checked) {
          // check if this is first checked value from this filter
          // if not, add operator befor value
          if (filtervalues.length > 0)
            filtervalues += operator;

          filtervalues += option.id;
        }
      });

      // check if any option values were added and update filtersString
      if (filtervalues.length > 0) {
        if (addKey)
          filtersString += "&" + filter.key + "=" ;

        filtersString += filtervalues;
      }
    });

    return filtersString;
  }

  buildFilterParams(obj): {[k: string]: any} {

    if (!this.filtersDefinition)
      return obj;


    this.filtersDefinition.forEach((filter) => {
      let filtervalues = "";
      let operator = ",";

      if (filter.operator === "or")
        operator = "|";
      else if (filter.operator === "and")
        operator = ",";


      filter.options.forEach((option) => {

        if (option.checked) {
          // check if this is first checked value from this filter
          // if not, add operator befor value
          if (filtervalues.length > 0)
            filtervalues += operator;

          filtervalues += option.label;
        }
      });

      // check if any option values were added and update filtersString
      if (filtervalues.length > 0)
        obj[filter.key] = filtervalues;

    });

    return obj;
  }

  onFiltersApplied() {
    this.updateUrl();
  }

  displaySaveDesignErrorPopup() {
    const options = {
      title: "Error",
      text: "Sorry, we are unable to save this design. Please try again.",
      buttons: [
        {
          text: "Close",
          callback: () => { this.popupService.hidePopup(); }
        }
      ]
    };

    this.popupService.showPopup(options);
    this.changeDetector.detectChanges();
  }

  onFilterChanged(event) {
    this.filtersDefinition.forEach((filter) => {
      if (filter.key === event.key)
        filter.options = event.options;

    });
    if (event.selectedChoice.checked === true) {
      const analyticsEventContext = {type: event.key, value: event.selectedChoice.label};
      this.intiaroAnalyticsClient.sendEvent(IntiaroAnalyticsConstants.AnalyticsAttrProductListFilterApplied, analyticsEventContext);
    }
    this.onFiltersApplied();
  }

  uncheckAllOptions(filter) {
    filter.options.forEach((option) => {
      option.checked = false;
    });
  }

  clearFilters(apply = true) {
    if (this.filtersDefinition) {
      this.filtersDefinition.forEach((filter) => {
        this.uncheckAllOptions(filter);
      });
    }
    if (apply)
      this.onFiltersApplied();
  }

  searchPhraseChanged(event) {
    this.isTouchedSearchInput = true;
    this.searchString = event.target.value;
    if (event.key === "Enter")
      this.startSearch();
  }

  startSearch() {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {};
    let url = urlTree.toString();
    url += this.addSearchParams();
    url += this.addSortingParams();

    this.updateUrl();
  }

  deleteDesignButtonClicked(myDesignId) {
    const options = {
      title: "Deleting design",
      text: "Are you sure you wish to delete this design?",
      buttons: [
        {
          text: "Yes",
          callback: () => { this.deleteMyDesign(myDesignId); this.popupService.hidePopup(); }
        },
        {
          text: "No",
          callback: () => { this.popupService.hidePopup(); }
        }
      ]
    };

    this.popupService.showPopup(options);
  }

  deleteMyDesign(myDesignId) {
    const deleteUrl = `${this.globalSettingsService.db3dBackendDomain}/api/product-design/${myDesignId}/`;

    this.http.delete(deleteUrl).subscribe((res) => {
      // From product details screen go to product list screen.
      // If already on product list, refresh list by downloading products.
      this.downloadProducts();
    });
  }

  productImageStateChanged(event: StateChange, product) {
    switch (event.reason) {
    case "setup":
      // The lib has been instantiated but we have not done anything yet.
      break;
    case "observer-emit":
      // The image observer (intersection/scroll/custom observer) has emit a value so we
      // should check if the image is in the viewport.
      // `event.data` is the event in this case.
      break;
    case "start-loading":
      // The image is in the viewport so the image will start loading
      break;
    case "mount-image":
      // The image has been loaded successfully so lets put it into the DOM
      break;
    case "loading-succeeded":
      // The image has successfully been loaded and placed into the DOM
      product.loaded = true;
      this.changeDetector.detectChanges();
      break;
    case "loading-failed":
      // The image could not be loaded for some reason.
      // `event.data` is the error in this case
      break;
    case "finally":
      // The last event before cleaning up
      break;
    }
  }

  ngOnDestroy() {
    clearIntiaroInstances();
    this.queryParamsSub.unsubscribe();
  }

  private applyFromUrlParams(queryParams) {
    this.clearFilters(false);
    this.applySearchFromUrlParams(queryParams);
    this.applySortingFromUrlParams(queryParams);
    this.applyFiltersFromUrlParams(queryParams);
  }

  private applySearchFromUrlParams(queryParams) {
    this.searchString = queryParams.value.search ?? "";
  }

  private applySortingFromUrlParams(queryParams) {
    this.selectedSorting = queryParams.value.order_by ?? "";
    this.sortingDefinition.options.forEach((option) => {
      option.checked = option.id === this.selectedSorting;
    });
  }

  applyFiltersFromUrlParams(urlParams) {
    if (urlParams.value.toString().includes(","))
      console.error("filtering with end operator is not supported");

    else{
      Object.keys(urlParams.value).forEach((filterName) => {
        this.filtersDefinition.forEach( (x) => {
          if (x.key === filterName) {
            x.options.forEach((y) => {
              if (urlParams.value[filterName].split("|").some((z) => { return z === y.label; }))
                y.checked = true;

            });
          }
        });
      });
    }
  }

  private updateUrl() {
    const obj: {[k: string]: any} = {};
    if (this.searchString !== "")
      obj.search = this.searchString;

    if (this.selectedSorting !== "")
      obj.order_by = this.selectedSorting;

    this.buildFilterParams(obj);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: obj
    });
  }

  onFiltersToggleClicked() {
    this.showFiltersMobile = !this.showFiltersMobile;
  }

  getFiltersParameters(): {[key: string]: any} {
    const filters = {};
    this.filtersDefinition.forEach((filter: Filter) => {
      const checkedOptions = filter.options.filter((option: FiltersAndSortingOption) => option.checked);
      if (checkedOptions.length > 0)
        filters[filter.key] = checkedOptions.map((option) => option.label).toString();
    });
    return filters;
  }

  getSortingParam(): string {
    const selectedOption = this.sortingDefinition.options.find((option: FiltersAndSortingOption) => option.checked);
    if(selectedOption) {
      return selectedOption.id.charAt(0) === "-" ? SortingMethod.desc : SortingMethod.asc;
    } else {
      return SortingMethod.none;
    }
  }

  shouldTrackSearchEvent(analyticsEventBody: SearchAndFiltersEventBody): boolean {
    return analyticsEventBody.search_value !== null || analyticsEventBody.sorting !== SortingMethod.none || Object.keys(analyticsEventBody.filters).length > 0;
  }

  removeQuery(): void {
    this.searchString = "";
    this.router.navigate([], {
      queryParams: {
        search: null
      },
    });
  }
}
