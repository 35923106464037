import { UsersPermission } from "../data-models/manage-users/user-permission.model";

export interface IUsersPermissionApiResponse {
  name: string;
  slug: string;
}

export class UsersPermissionFactory {

  public createFromBackendApi(apiResponse: IUsersPermissionApiResponse): UsersPermission {
    return new UsersPermission(
      apiResponse.name,
      apiResponse.slug,
    );
  }
}