import { AuthResponseData } from "src/app/data-models/auth-response-data.model";
import { Injectable } from "@angular/core";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { AvailableUserStatuses } from "../user-status.service";

export interface IAccountStatus{
  status: string;
}
export class TrialAccountStatus implements IAccountStatus {
  public status: string;
  public expirationDate: string;
  constructor(status: string, expirationDate: string) {
    this.status = status;
    this.expirationDate = expirationDate;
  }
}
export class BlockedAccountStatus implements IAccountStatus {
  public status: string;
  public expirationDate: string;
  constructor(status: string) {
    this.status = status;
  }
}
export class RejectedAccountStatus implements IAccountStatus {
  public status: string;
  constructor(status: string) {
    this.status = status;
  }
}

export enum UserPermissions {
  Admin = "wl_admin",
  ViewPrices = "can_view_prices",
  ViewDownload3D  = "can_view_download3d",
  Designer  = "designer",
  ViewProducts = "can_view_products",
  ViewProductConfigDetails= "can_view_product_config_details"
}

export class User {
  private _token : string;
  private _permissions: string[];
  private _authResponseData: AuthResponseData;
  private _tokenExpDate: Date;
  private _userName: string;
  private _id: number;
  private _user_uuid: string;
  private _accountStatus: IAccountStatus;
  private _designerId: number;
  private _db3dToken: string;
  private _wlSlug: string;
  private _company: string;
  private _jobPosition: string;
  private _portfolioUrl: string;
  private _phone: string;

  public autologin: boolean;
  private _email: string;
  private _analytics_attributes: Array<any>;
  private _status: AvailableUserStatuses;

  constructor(data?: AuthResponseData, email?: string) {
    if (data) {
      this._token = data.intiaro_backend_token;
      this._db3dToken = data.token;
      this._tokenExpDate = new Date( new Date().getTime() + data.valid * 1000);
      this._email = email;
      this._userName = data.user_name || "";
      this._permissions = data.permissions || [];
      this._id = data.id;
      this.autologin = false;
      this._user_uuid = data.user_uuid;
      this._wlSlug = data.wl_slug;
      this._status = data.status;
    }
  }

  get token() {
    if (!this._tokenExpDate || new Date() > this._tokenExpDate) return null;
    return this._token;
  }

  get db3dToken() {
    return this._db3dToken;
  }

  get tokenExpDate() {
    return this._tokenExpDate;
  }

  get userName() {
    return this._userName;
  }

  set userName(name) {
    this._userName = name;
  }

  get permissions() {
    return this._permissions;
  }

  set permissions(newPermissions: string[]) {
    this._permissions = newPermissions;
  }

  get id() {
    return this._id;
  }
  get isAuthenticated() {
    return (!!this._user_uuid);
  }
  get uuid() {
    return this._user_uuid;
  }
  get wlSlug() {
    return this._wlSlug;
  }
  get accountStatus() {
    return this._accountStatus;
  }
  set accountStatus(value) {
    this._accountStatus = value;
  }
  get designerId() {
    return this._designerId;
  }
  get email() {
    return this._email;
  }

  set analytics_attributes(analyticsAttributes) {
    this._analytics_attributes = analyticsAttributes;
  }

  get analytics_attributes() {
    return this._analytics_attributes;
  }

  get company() {
    return this._company;
  }

  set company(companyName) {
    this._company = companyName;
  }

  get jobPosition() {
    return this._jobPosition;
  }

  set jobPosition(jobPosition) {
    this._jobPosition = jobPosition;
  }

  get portfolioUrl() {
    return this._portfolioUrl;
  }

  set portfolioUrl(portfolio) {
    this._portfolioUrl = portfolio;
  }

  get phone() {
    return this._phone;
  }

  set phone(phoneNumber) {
    this._phone = phoneNumber;
  }

  get status() {
    return this._status;
  }

  set status(_status) {
    this._status = _status;
  }
}

@Injectable({
  providedIn: "root"
})
export class UserService {
  protected currentUser: User;

  constructor(
    private whiteLabelConfigurationService: WhiteLabelConfigurationService
  ) {}

  public setCurrentUser(user: User) {
    this.currentUser = user;
  }

  public getCurrentUser(): User {
    return this.currentUser;
  }

  public isWhiteLabelAdmin(): boolean {
    return this.currentUser?.permissions.includes(UserPermissions.Admin);
  }

  public isLoggedUser(): boolean {
    return this.currentUser?.isAuthenticated;
  }

  public canUserViewPrices(): boolean {
    let isPricesVisible: boolean;
    this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.subscribe((val) => {
      isPricesVisible = val.showPrices;
    });
    const visiblePricesForUser = this.currentUser?.permissions.includes(UserPermissions.ViewPrices);
    return (!!visiblePricesForUser && isPricesVisible);
  }

  public canViewDownload3D(): boolean {
    return this.currentUser?.permissions.includes(UserPermissions.ViewDownload3D);
  }

  public canViewProductConfigDetails() {
    return this.currentUser?.permissions.includes(UserPermissions.ViewProductConfigDetails);
  }
}

