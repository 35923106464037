import { ActivatedRoute, Router } from "@angular/router";
import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { QueryParamsForGet } from "src/app/services/api/db3d-api-backend-client.service";
import { NavigationService } from "src/app/services/NavigationService.service";
import { UserRolesDropdownService } from "src/app/services/user-roles-dropdown.service";
import { queryParamsKey } from "../../manage-roles/roles-list/roles-list.component";

@Component({
  selector: "app-action-dropdown",
  templateUrl: "./action-dropdown.component.html",
  styleUrls: ["./action-dropdown.component.scss"]
})
export class ActionDropdownComponent implements OnInit, OnDestroy {

  @Input() disabled: boolean;
  @Input() roleId: string;
  @Input() duplicateAction: string;
  @Input() detailsAction: string;
  @Output() deleteRoleWithSlug: EventEmitter<string> = new EventEmitter();

  private dropdownSubscription: Subscription;
  public dropdownVisible: boolean = false;

  @HostListener("document:click", ["$event"])
  handleClick(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) this.closeDropdown();
  }

  constructor(
    private router: Router,
    public navigationService: NavigationService,
    private userRolesDropdownService: UserRolesDropdownService,
    private route: ActivatedRoute,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.dropdownSubscription = this.userRolesDropdownService.dropdownClicked$.subscribe((clickedDropdown) => {
      if (clickedDropdown !== this) this.closeDropdown();
    });
  }

  public chooseAction(): void {
    this.userRolesDropdownService.notify(this);
    this.dropdownVisible = !this.dropdownVisible;
  }

  private closeDropdown() {
    this.dropdownVisible = false;
  }

  public showDetails(): void {
    const queryParamsFromUrl: QueryParamsForGet = {
      page: this.route.snapshot.queryParamMap.get(queryParamsKey.page),
      search: this.route.snapshot.queryParamMap.get(queryParamsKey.search),
      ordering: this.route.snapshot.queryParamMap.get(queryParamsKey.sort),
    };
    this.router.navigate([this.detailsAction], {
      queryParams: queryParamsFromUrl,
      relativeTo: this.route,
      queryParamsHandling: "merge",
      skipLocationChange: false
    });
    
    this.closeDropdown();
  }

  public duplicate(): void {
    const queryParamsFromUrl: QueryParamsForGet = {
      page: this.route.snapshot.queryParamMap.get(queryParamsKey.page),
      search: this.route.snapshot.queryParamMap.get(queryParamsKey.search),
      ordering: this.route.snapshot.queryParamMap.get(queryParamsKey.sort),
    };
    this.router.navigate([this.duplicateAction], {
      queryParams: queryParamsFromUrl,
      relativeTo: this.route,
      queryParamsHandling: "merge",
      skipLocationChange: false
    });
    this.closeDropdown();
  }

  public delete(): void {
    if(!this.disabled) {
      this.deleteRoleWithSlug.emit(this.roleId);
      this.closeDropdown();
    }
  }

  ngOnDestroy(): void {
    this.dropdownSubscription.unsubscribe();
  }
}
