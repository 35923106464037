import { AfterViewInit, Component, Input } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"]
})
export class SearchComponent implements AfterViewInit {
  @Input() searchPhrase: BehaviorSubject<string> = new BehaviorSubject("");
  deleteQueryButtonFlag = false;

  constructor() {}

  ngAfterViewInit() {
    this.toggleQueryDeleteBtn();
  }

  public onSearchChange(value:string):void {
    this.searchPhrase.next(value);

    this.toggleQueryDeleteBtn();
  }

  public removeQuery(): void {
    this.searchPhrase.next("");
    this.deleteQueryButtonFlag = false;
  }

  private toggleQueryDeleteBtn(): void {
    this.deleteQueryButtonFlag = (this.searchPhrase.value.length > 0);
  }
}
