import {UserService} from "../../authentication/user.service";
import {ActivatedRouteSnapshot, Router, UrlTree} from "@angular/router";
import {IGuardMixin} from "./IGuardMixin";
import { UserStatusesRegistry } from "../../user-status.service";

export class UnverifiedGuardMixin implements IGuardMixin {
  constructor(protected userService: UserService) {}

  getRedirectUrlTree(router: Router, route: ActivatedRouteSnapshot): UrlTree {
    return router.createUrlTree(["/"]);
  }

  public evaluate(_route: ActivatedRouteSnapshot): boolean {
    return this.userService.getCurrentUser().status !== UserStatusesRegistry.active;
  }

}
