/* eslint-disable arrow-body-style */
/* eslint-disable curly */
import {Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { filter } from "rxjs";
import { WhiteLabelSetupConfiguration } from "src/app/data-models/wl-account-setup/wl-config-data.model";
import { NavigationService } from "src/app/services/NavigationService.service";
import { VerificationValidatorService } from "src/app/services/VerificationValidatorService.service";
import { AuthService } from "src/app/services/auth.service";
import { UserDetailsService } from "src/app/services/user-details.service";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { UserDetails } from "src/app/data-models/manage-users/user-details.model";
import { RedirectByUserStatusService, UserStatusesRegistry } from "src/app/services/user-status.service";

@Component({
  selector: "app-verification",
  templateUrl: "./verification.component.html",
  styleUrls: ["../authentication-module.scss"],
  providers:[VerificationValidatorService]
})
export class VerificationComponent implements OnInit {
  
  public showContent: boolean = false;

  constructor(
    private authService: AuthService,
    public navigationService : NavigationService,
    private verificationValidatorService: VerificationValidatorService,
    private whiteLabelConfigurationService: WhiteLabelConfigurationService,
    private userDetailsService: UserDetailsService,
    private redirectByUserStatusService: RedirectByUserStatusService,
    private router: Router
  ) {}

  public logOut(): void {
    this.authService.logout(["/"]);
  }
  
  ngOnInit(): void {
    this.validateAndRedirect();
  }

  private validateAndRedirect(): void {
    this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.pipe(
      filter((wlSetupConfig: WhiteLabelSetupConfiguration) => wlSetupConfig.slug !== ""),
    ).subscribe((wlSetupConfig: WhiteLabelSetupConfiguration) => {

      this.userDetailsService.currentUserDetails.pipe(
        filter((currentUserDetails: UserDetails) => currentUserDetails !== null),
      ).subscribe((currentUserDetails: UserDetails) => {

        if(currentUserDetails.status !== UserStatusesRegistry.pending) {
          this.redirectByUserStatusService.redirectUsersWithNotActiveStatus(currentUserDetails.status);
          return;
        }

        if(this.verificationValidatorService.validate(wlSetupConfig.twoStepRegistration, currentUserDetails)) {
          this.showContent = true;
        }else {
          this.router.navigate([this.navigationService.lastStep]);
        }
      });
    
    });    
  }

}