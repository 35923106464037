import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-toggle-switch",
  templateUrl: "./toggle-switch.component.html",
  styleUrls: ["./toggle-switch.component.scss"]
})
export class ToggleSwitchComponent {

  @Input() id: string;
  @Input() disabled = false;
  @Input() checked = false;
  @Output() toggled = new EventEmitter<{id: string, checked: boolean}>();

  toggleSwitch(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.toggled.emit({id: this.id, checked: inputElement.checked});
  }
}
