import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationService } from "./NavigationService.service";

export enum UserStatusesRegistry {
  active = "active",
  blocked = "blocked",
  pending = "pending",
}
export type AvailableUserStatuses = typeof UserStatusesRegistry[keyof typeof UserStatusesRegistry];

@Injectable({ providedIn: "root" })
export class RedirectByUserStatusService {

  private statusToRoute: Map<string, string>;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
  ) {
    this.statusToRoute = new Map<string, string>([
      [UserStatusesRegistry.blocked, this.navigationService.blocked],
      [UserStatusesRegistry.pending, this.navigationService.verification],
      [UserStatusesRegistry.active, ""]
    ]);
  }

  public redirectUsersWithNotActiveStatus(status: AvailableUserStatuses): void {
    const route = this.statusToRoute.get(status);
    if(route === undefined) return;
    this.router.navigate([route]);
  }
}