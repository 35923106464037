import { Injectable } from "@angular/core";
import { Db3DApiBackendClient } from "./api/db3d-api-backend-client.service";
import { environment } from "src/environments/environment";
import { UsersRoleFactory } from "../factories/user-role-factory";
import { BehaviorSubject } from "rxjs";
import { UsersPermission } from "../data-models/manage-users/user-permission.model";
import { IUsersPermissionApiResponse, UsersPermissionFactory } from "../factories/user-permission-factory";

export enum textErrMessages {
  general = "Sorry, something went wrong. Please try again.",
  getPermission = "Something went wrong with building permissions list.",
  getConfig = "Sorry, there is problem with service configuration.",
  loadDetails = "Loading role details error.",
  loadPermissions = "Loading permissions list error.",
  serverErr = "Internal sever error.",
  ststus404 = "Http failure response.",
}

export type UserPermissionWithCheckedFlag = {
  name: string,
  slug: string,
  checked: boolean,
}

@Injectable({
  providedIn: "root"
})
export class UserRolesManageService {

  private usersRoleFactory: UsersRoleFactory = new UsersRoleFactory();
  private usersPermissionFactory: UsersPermissionFactory = new UsersPermissionFactory();
  public userRoleName: BehaviorSubject<string> = new BehaviorSubject("");
  public permissionMessage: BehaviorSubject<string> = new BehaviorSubject("");
  public permissionsList: BehaviorSubject<Array<UsersPermission>> = new BehaviorSubject([]);

  constructor(
    private db3DApiBackendClient: Db3DApiBackendClient,
  ) {}

  public getRoleDetails(userRoleSlug: string, wlSlug: string): void {
    this.db3DApiBackendClient.getRoleDetails(environment.db3dBackendDomain, userRoleSlug, wlSlug).subscribe({
      next: (details) => {
        if(details) {
          const roleDetails = this.usersRoleFactory.createFromBackendApi(details);
          this.userRoleName.next(roleDetails.name);

          if(roleDetails.permissions) this.checkPermissionsList(roleDetails.permissions);
          else this.permissionMessage.next(textErrMessages.general);
        } else this.permissionMessage.next(textErrMessages.general);
      }, 
      error: (err) => {
        this.permissionMessage.next(textErrMessages.loadDetails);
      }
    });
  }

  public checkPermissionsList(permissions: Array<UsersPermission>): void {
    const activeUserPermissionsSlug = permissions.map((item) => item.slug);

    this.db3DApiBackendClient.getPermissionsList(environment.db3dBackendDomain).subscribe({
      next: (permissions) => {
        const permissionsList = this.buildUsersPermissionList(permissions);
        if(permissionsList) this.checkActivePermissions(permissionsList, activeUserPermissionsSlug);
        else this.permissionMessage.next(textErrMessages.getPermission);
      },
      error: (err) => {
        this.permissionMessage.next(textErrMessages.loadPermissions);
      }
    });
  }

  private buildUsersPermissionList(permissionsFromApi: Array<IUsersPermissionApiResponse>): Array<UsersPermission> {
    return permissionsFromApi.map( (permissionsRoleJson) => {
      return this.usersPermissionFactory.createFromBackendApi(permissionsRoleJson);
    });
  }

  private checkActivePermissions(permissionsList: Array<UsersPermission>, activeUserPermissions: Array<string>): void {
    permissionsList.forEach( (el: UsersPermission) => {
      el.checked = activeUserPermissions.includes(el.slug);
    });
    this.permissionsList.next(permissionsList);
  }

  public startWithEmptyValues(): void {
    this.userRoleName.next("");
    this.permissionsList.next([]);
    this.permissionMessage.next("");
  }
}