import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest, Observable, Subject, Subscription } from "rxjs";
import { UsersPermission } from "src/app/data-models/manage-users/user-permission.model";
import { Db3DApiBackendClient, QueryParamsForGet } from "src/app/services/api/db3d-api-backend-client.service";
import { NavigationService, RaoutesRegistry } from "src/app/services/NavigationService.service";
import { UserRolesManageService, textErrMessages } from "src/app/services/user-roles-manage.service";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { environment } from "src/environments/environment";
import { Utils } from "src/app/utils/utils";
import { ToastService, ToastType } from "src/app/services/toast.service";
import { PopupService, unsavedChangesPopupTexts } from "src/app/services/popup.service";
import { queryParamsKey } from "../roles-list/roles-list.component";

export type UpdatedRole = {
  name?: string,
  slug?: string,
  permissions?: Array<{name: string, slug: string}>,
}

export enum ViewMode {
  showDetailsRole = "detailsMode",
  editRole = "editMode",
  addNewRole = "addNewRoleMode",
  duplicateRole = "duplicateRole",
}

@Component({
  selector: "app-role-details-edit",
  templateUrl: "./role-details.component.html",
  styleUrls: ["./role-details.component.scss"]
})
export class RoleDetailsEditComponent implements OnInit, OnDestroy {

  public duplicateRole: boolean = false;
  public roleViewMode: string;
  public pageTitle: string;
  public saveButtonText: string;

  public wlSlug: string;
  public userRoleSlug: string;
  public userRoleName: string = "";
  public permissionsList: Array<UsersPermission>;
  public permissionMessage: string;
  private subscription: Subscription;

  private permissionsListStartState: Array<UsersPermission>;
  private confirmNavigationSubject: Subject<boolean> = new Subject<boolean>();
  public priorityForUserDecisionOfRedirection: boolean = false;
  private isRequestProcessing: boolean = false;
  private isCancelProcessing: boolean = false;
  userRoleNameFocused: boolean;
  initialUserRoleName: string;

  constructor(
    private router: Router,
    public navigationService: NavigationService,
    private whiteLabelConfigurationService: WhiteLabelConfigurationService,
    private activatedRoute: ActivatedRoute,
    private userRolesManageService: UserRolesManageService,
    private db3DApiBackendClient: Db3DApiBackendClient,
    private toastService: ToastService,
    private popupService: PopupService,
  ) {
    const modes = [
      { modeName: ViewMode.addNewRole, url: RaoutesRegistry.addNewRole, pageTitle: "Create new role", saveButtonText: "Add new role" },
      { modeName: ViewMode.duplicateRole, url: RaoutesRegistry.roleDetailsDuplicate, pageTitle: "Duplicate role", saveButtonText: "Duplicate role" },
      { modeName: ViewMode.editRole, url: RaoutesRegistry.roleDetailsEdit, pageTitle: "Edit role", saveButtonText: "Save role"}
    ];
    const currentViewMode = modes.find( ({url}) => this.router.url.includes(url));
    this.roleViewMode = currentViewMode.modeName;
    this.saveButtonText = currentViewMode.saveButtonText;
    this.pageTitle = currentViewMode.pageTitle;
  }

  @HostListener("window:beforeunload", ["$event"])
  windowBeforeUnload($event: BeforeUnloadEvent) {
    if(!this.canLeavePage()) $event.returnValue = unsavedChangesPopupTexts.warning;
  }

  canDeactivate(): Observable<boolean> | boolean {
    const popupOptionsUnsaved = {
      title: unsavedChangesPopupTexts.title,
      text: [unsavedChangesPopupTexts.subtitle],
      buttons: [
        {
          text: unsavedChangesPopupTexts.saveBtn,
          callback: () => {
            this.priorityForUserDecisionOfRedirection = true;
            if(this.roleViewMode !== ViewMode.editRole)
              this.saveNewRole();
            else
              this.saveEditedRole();
            this.confirmNavigationSubject.next(true);
            this.popupService.hidePopup();
          }
        }
      ],
      secondaryButtons: [
        {
          text: unsavedChangesPopupTexts.leaveBtn,
          callback: () => {
            this.confirmNavigationSubject.next(true);
            this.popupService.hidePopup();
          }
        }
      ]
    };

    if(this.canLeavePage()) 
      return true;
    else {
      this.popupService.showPopup(popupOptionsUnsaved);
      return this.confirmNavigationSubject.asObservable();
    }
  }

  ngOnInit(): void {
    this.userRolesManageService.startWithEmptyValues();
    this.userRoleSlug = this.activatedRoute.snapshot.paramMap.get("id");

    this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.subscribe({
      next: (config) => {
        this.wlSlug = config.slug;
        if(this.wlSlug) {
          if(this.roleViewMode === ViewMode.addNewRole)
            this.userRolesManageService.checkPermissionsList([]);
          else
            this.userRolesManageService.getRoleDetails(this.userRoleSlug, this.wlSlug);
        }
      },
      error: () => {
        this.permissionMessage = textErrMessages.getConfig;
      }
    });

    this.subscription = combineLatest([
      this.userRolesManageService.userRoleName,
      this.userRolesManageService.permissionsList,
      this.userRolesManageService.permissionMessage
    ]).subscribe({
      next: ([name, list, msg]) => {
        if(this.roleViewMode === ViewMode.duplicateRole && name)
          this.userRoleName = `${name} copy`;
        else 
          this.userRoleName = name;

        this.permissionsList = list;
        this.permissionsListStartState = Utils.deepCopy(list);
        this.permissionMessage = msg;

        this.initialUserRoleName = this.userRoleName;
      },
      error: (error) => {
        this.permissionMessage = textErrMessages.loadDetails;
      }
    });
  }

  public handleToggle(event: {id: string, checked: boolean}): void {
    this.permissionsList.find( (el) => {
      if(el.slug === event.id) el.checked = event.checked;
    });
  }

  public isNewDataUnsaved(): boolean {
    if(this.roleViewMode === ViewMode.addNewRole)
      return this.isRoleNameEdited();
    else if(this.roleViewMode === ViewMode.duplicateRole)
      return !this.isNewNameIncorrect();
    else
      return (this.isRoleNameEdited() || !Utils.isObjectsListEqual(this.permissionsList, this.permissionsListStartState)) && !this.isNewNameIncorrect();

  }

  private isRoleNameEdited(): boolean {
    return (this.userRoleName !== this.initialUserRoleName);
  }

  public backToPreviousView() {
    this.isCancelProcessing = true;
    const queryParamsFromUrl: QueryParamsForGet = {
      page: this.activatedRoute.snapshot.queryParamMap.get(queryParamsKey.page),
      search: this.activatedRoute.snapshot.queryParamMap.get(queryParamsKey.search),
      ordering: this.activatedRoute.snapshot.queryParamMap.get(queryParamsKey.sort),
    };
    if(this.roleViewMode === ViewMode.duplicateRole)
      this.router.navigate([this.navigationService.manageRoles], { queryParams: queryParamsFromUrl });
    else
      this.router.navigate([this.navigationService.manageRoleWithSlug(this.userRoleSlug)], { queryParams: queryParamsFromUrl });
  }

  public cancelEditedRole(): void {
    this.backToPreviousView();
  }

  public saveChanges(): void {
    if(this.roleViewMode === ViewMode.editRole)
      this.saveEditedRole();
    else
      this.saveNewRole();
  }

  public saveNewRole(): void {
    this.isRequestProcessing = true;
    const payload: UpdatedRole = {};
    payload.name = this.userRoleName;

    payload.permissions = [];
    this.permissionsList.forEach( (permission) => {
      if(permission.checked) payload.permissions.push({name: permission.name, slug: permission.slug});
    });

    this.db3DApiBackendClient.createNewRole(environment.db3dBackendDomain, this.wlSlug, payload).subscribe({
      next: (resp) => {
        this.executeAfterSuccessRequest();
      },
      error: (err) => {
        this.executeAfterErrorRequest(err);
      }
    });
  }

  public saveEditedRole(): void {
    this.isRequestProcessing = true;
    const payload: UpdatedRole = {};
    if(this.initialUserRoleName !== this.userRoleName) payload.name = this.userRoleName;

    if(!Utils.isObjectsListEqual(this.permissionsList, this.permissionsListStartState)) {
      payload.permissions = [];
      this.permissionsList.forEach( (permission) => {
        if(permission.checked) payload.permissions.push({name: permission.name, slug: permission.slug});
      });
    }

    this.db3DApiBackendClient.updateUserRole(environment.db3dBackendDomain, this.wlSlug, this.userRoleSlug, payload).subscribe({
      next: (resp) => {
        this.executeAfterSuccessRequest();
      },
      error: (err) => {
        this.executeAfterErrorRequest(err);
      }
    });
  }

  private executeAfterSuccessRequest(): void {
    this.toastService.showToast("Changes saved!", ToastType.success);
    if(!this.priorityForUserDecisionOfRedirection) this.backToPreviousView();
  }

  private executeAfterErrorRequest(error: any): void {
    this.isRequestProcessing = false;
    this.toastService.showToast("Changes unsaved!", ToastType.error);

    if(error.error?.name)
      this.permissionMessage = error.error.name;
    else
      this.permissionMessage = textErrMessages.general;
    if(error.status === 403) this.router.navigate([this.navigationService.pageNotFound]);
    if(error.status === 404) this.permissionMessage = textErrMessages.ststus404;
    if(error.status === 500) this.permissionMessage = textErrMessages.serverErr;
    setTimeout(() => {
      this.permissionMessage = "";
    }, 3500);
  }

  private canLeavePage(): boolean {
    return !this.isNewDataUnsaved() || this.isRequestProcessing || this.isCancelProcessing;
  }

  public isNewNameIncorrect(): boolean {
    return (typeof this.userRoleName === "string") && ((this.userRoleName?.length === 0) || (this.userRoleName?.length > 50));
  }

  ngOnDestroy(): void {
    if(this.subscription) this.subscription.unsubscribe();
  }
}