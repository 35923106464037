import { Component, Input, Output, EventEmitter } from "@angular/core";


@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"]
})
export class PaginationComponent {
  @Input() paginatorPage: number;
  @Input() isFirstPage: boolean = false;
  @Input() isLastPage: boolean = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onPageChangeEvent = new EventEmitter<number>();

  public getNextPage(): void {
    if(!this.isLastPage) this.onPageChangeEvent.emit(this.paginatorPage + 1);
  }

  public getPreviousPage(): void {
    if(!this.isFirstPage) this.onPageChangeEvent.emit(this.paginatorPage - 1);
  }
}
