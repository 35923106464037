import { UsersPermission } from "../data-models/manage-users/user-permission.model";
import { UsersRole } from "../data-models/manage-users/user-role.model";

export interface IUsersRoleApiResponse {
  name: string;
  slug: string;
  disabled: boolean,
  user_count: number,
  // id?: number,
  permissions?: Array<UsersPermission>
}

export class UsersRoleFactory {

  public createFromBackendApi(apiResponse: IUsersRoleApiResponse): UsersRole {
    return new UsersRole(
      apiResponse.name,
      apiResponse.slug,
      apiResponse.disabled,
      apiResponse.user_count,
      // apiResponse.id,
      apiResponse.permissions
    );
  }
}
