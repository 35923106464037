export class Utils {
  static deepCopy<Object>(obj: Object): Object {
    return JSON.parse(JSON.stringify(obj));
  }

  static isObjectsListEqual(arr1: Array<any>, arr2: Array<any>): boolean {
    const utilsInstance = new Utils();
    if(arr1.length !== arr2.length) return false;
    for(let i = 0; i < arr1.length; i++) { 
      if(!utilsInstance.deepCompare(arr1[i], arr2[i])) return false;
    }
    return true;
  }

  deepCompare(obj1: any, obj2: any): boolean {
    if(obj1 === obj2) return true;
    if(obj1 === null || typeof obj1 !== "object" || obj2 === null || typeof obj2 !== "object") return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if(keys1.length !== keys2.length) return false;

    for(const key of keys1) {
      if(!keys2.includes(key) || !this.deepCompare(obj1[key], obj2[key])) return false;
    }
    return true;
  }
}