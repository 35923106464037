import { UsersPermission } from "./user-permission.model";

export class UsersRole {
  constructor(
    public name: string,
    public slug: string,
    public disabled: boolean,
    public userCount: number,
    // public id?: number,
    public permissions?: Array<UsersPermission>
  ) {}
}
