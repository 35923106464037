import { Component, ChangeDetectorRef, AfterViewChecked, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { NavigationService, RaoutesRegistry } from "src/app/services/NavigationService.service";
import { take } from "rxjs";

enum menuItemsSlug {
  manageUsers = "users-management",
  accountSetup = "wl-configuration",
  manageRoles = "manage-roles"
}

export class AdminMenuItem {

  constructor(
    public readonly title: string,
    public readonly iconPath: string,
    public readonly navigateTo: string,
    public readonly slug: string, // must be unique
    private active?: boolean
  ) {
    if (this.active === undefined) this.active = false;
  }

  public setNotActive() {
    this.active = false;
  }

  public setActive() {
    this.active = true;
  }

  public isActive() {
    return this.active;
  }
}

export class AdminMenuStructure {
  private readonly navigationService: NavigationService = new NavigationService();
  private adminMenuItems: Array<AdminMenuItem> = [];

  constructor() {
    this.adminMenuItems.push(new AdminMenuItem(
      "Manage users",
      "assets/images/icons/admin-view/manage-users.svg",
      this.navigationService.adminUsers,
      menuItemsSlug.manageUsers
    ));
    this.adminMenuItems.push(new AdminMenuItem(
      "Account settings",
      "assets/images/icons/admin-view/account-settings.svg",
      this.navigationService.adminAccountSetup,
      menuItemsSlug.accountSetup
    ));
    this.adminMenuItems.push(new AdminMenuItem(
      "Manage roles",
      "assets/images/manage_roles.svg",
      this.navigationService.manageRoles,
      menuItemsSlug.manageRoles
    ));
  }

  public getMenuItems() {
    return this.adminMenuItems;
  }
}

enum menuTitlesEnum  {
  "Manage users",
  "Account settings",
  "Manage roles"
}

@Component({
  selector: "app-admin-view",
  templateUrl: "./admin-view.component.html",
  styleUrls: ["./admin-view.component.scss"]
})
export class AdminViewComponent implements AfterViewChecked, OnInit {
  public menuStructure: AdminMenuStructure;
  static readonly manageUsersTabMenu = menuItemsSlug.manageUsers;
  static readonly accountSetupTabMenu = menuItemsSlug.accountSetup;
  static readonly manageRolesTabMenu = menuItemsSlug.manageRoles;
  public isTopNavbarInfoVisible = false;
  public isMenuHidden: boolean;
  activeMenuTabIndex: number;
  usersQuantity: number;
  activeMenuTitle: string;

  constructor(
    private adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef
  ) {
    this.menuStructure = this.adminService.menuStructure;
    this.route.children[0].data.subscribe((extraData) => {
      this.adminService.setActiveTab(extraData.menuTab);
    });

    this.adminService.currentActiveItem.pipe(take(1)).subscribe((currentActiveItem) => {
      this.activeMenuTitle = currentActiveItem?.title;
      this.activeMenuTabIndex = menuTitlesEnum[this.activeMenuTitle] || 0 ;
    });
  }
  ngOnInit() {
    this.router.events.subscribe(() => {
      this.hideMenu();
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  public changeActiveTab(item: AdminMenuItem, menuItemIndex: number): void {
    this.activeMenuTabIndex = menuItemIndex;
    this.adminService.setActiveTab(item.slug);
    this.activeMenuTitle = item.title;
    
    this.router.navigate([item.navigateTo]);
  }

  public hideMenu(): void {
    const tabsWithoutVisibleMenu = [RaoutesRegistry.manageRoles, RaoutesRegistry.adminUsers];
    this.isMenuHidden = tabsWithoutVisibleMenu.some( (tab) => this.router.url.includes(`${tab}/`) );
  }
}
