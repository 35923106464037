import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ActionDropdownComponent } from "../components/common-elements/action-dropdown/action-dropdown.component";

@Injectable({
  providedIn: "root"
})
export class UserRolesDropdownService {
  private dropdownSubject = new Subject<ActionDropdownComponent>();

  dropdownClicked$ = this.dropdownSubject.asObservable();

  notify(dropdown: ActionDropdownComponent) {
    this.dropdownSubject.next(dropdown);
  }
}