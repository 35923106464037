import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest, Subscription } from "rxjs";
import { UsersPermission } from "src/app/data-models/manage-users/user-permission.model";
import { QueryParamsForGet } from "src/app/services/api/db3d-api-backend-client.service";
import { NavigationService } from "src/app/services/NavigationService.service";
import { UserRolesManageService, textErrMessages } from "src/app/services/user-roles-manage.service";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { queryParamsKey } from "../roles-list/roles-list.component";
import { ViewMode } from "./role-details-edit.component";

@Component({
  selector: "app-role-details",
  templateUrl: "./role-details.component.html",
  styleUrls: ["./role-details.component.scss"]
})
export class RoleDetailsComponent implements OnInit, OnDestroy {

  public readonly pageTitle = "Role details";
  public roleViewMode = ViewMode.showDetailsRole;
  public userRoleSlug: string;
  public wlSlug: string;
  public userRoleName: string;
  public permissionsList: Array<UsersPermission>;
  public permissionMessage: string;
  public editBtnDisabled: boolean = true;
  private subscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private whiteLabelConfigurationService: WhiteLabelConfigurationService,
    private router: Router,
    public navigationService: NavigationService,
    private userRolesManageService: UserRolesManageService
  ) {}

  ngOnInit(): void {
    this.userRolesManageService.startWithEmptyValues();
    this.userRoleSlug = this.activatedRoute.snapshot.paramMap.get("id");

    this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.subscribe({
      next: (config) => {
        this.wlSlug = config.slug;
        if(this.wlSlug) this.userRolesManageService.getRoleDetails(this.userRoleSlug, this.wlSlug);
      },
      error: () => {
        this.permissionMessage = textErrMessages.getConfig;
      }
    });

    this.subscription = combineLatest([
      this.userRolesManageService.userRoleName,
      this.userRolesManageService.permissionsList,
      this.userRolesManageService.permissionMessage
    ]).subscribe({
      next: ([name, list, msg]) => {
        this.userRoleName = name;
        this.permissionsList = list;
        this.permissionMessage = msg;
        this.editBtnDisabled = list.length === 0;
      },
      error: (error) => {
        this.permissionMessage = textErrMessages.loadDetails;
      }
    });
  }
  
  public backToPreviousView() {
    const queryParamsFromUrl: QueryParamsForGet = {
      page: this.activatedRoute.snapshot.queryParamMap.get(queryParamsKey.page),
      search: this.activatedRoute.snapshot.queryParamMap.get(queryParamsKey.search),
      ordering: this.activatedRoute.snapshot.queryParamMap.get(queryParamsKey.sort),
    };
    this.router.navigate([this.navigationService.manageRoles], {
      queryParams: queryParamsFromUrl,
      relativeTo: this.activatedRoute,
      queryParamsHandling: "merge",
      skipLocationChange: false
    });
  }

  public editRole(): void {
    const queryParamsFromUrl: QueryParamsForGet = {
      page: this.activatedRoute.snapshot.queryParamMap.get(queryParamsKey.page),
      search: this.activatedRoute.snapshot.queryParamMap.get(queryParamsKey.search),
      ordering: this.activatedRoute.snapshot.queryParamMap.get(queryParamsKey.sort),
    };
    this.router.navigate([this.navigationService.manageRoleWithSlugEdit(this.userRoleSlug)], {
      queryParams: queryParamsFromUrl,
      relativeTo: this.activatedRoute,
      queryParamsHandling: "merge",
      skipLocationChange: false
    });
  }

  ngOnDestroy() {
    if(this.subscription) this.subscription.unsubscribe();
  }
}
