import { Component, DoCheck, ElementRef, OnInit, QueryList, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, debounceTime } from "rxjs";
import { UsersRole } from "src/app/data-models/manage-users/user-role.model";
import { UsersPagination } from "src/app/data-models/manage-users/users-pagination.model";
import { IUsersRoleApiResponse, UsersRoleFactory } from "src/app/factories/user-role-factory";
import { UsersPaginationFactory } from "src/app/factories/users-pagination-factory";
import { Db3DApiBackendClient, QueryParamsForGet } from "src/app/services/api/db3d-api-backend-client.service";
import { NavigationService } from "src/app/services/NavigationService.service";
import { PopupService } from "src/app/services/popup.service";
import { ToastService, ToastType } from "src/app/services/toast.service";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { environment } from "src/environments/environment";
import { UsersDataFetchMessages } from "src/app/data-models/manage-users/users-data-fetch-messages";

export enum queryParamsKey {
  page = "page",
  search = "search",
  sort = "ordering",
}

enum sortDirection {
  asc = "ascending",
  desc = "descending",
  ascPrefix = "",
  descPrefix = "-",
}

type SortDirection = "ascending" | "descending" | null;

@Component({
  selector: "app-roles-list",
  templateUrl: "./roles-list.component.html",
  styleUrls: ["./roles-list.component.scss"]
})
export class RolesListComponent implements OnInit, DoCheck {

  private readonly usersQuerySearchDelay: number = 250;
  private usersRoleFactory: UsersRoleFactory = new UsersRoleFactory();
  public roles: Array<UsersRole>;
  public wlSlug: string;
  public message: string = UsersDataFetchMessages.loading;
  public searchInputValue = new BehaviorSubject<string>("");
  private lastSearchQuery: string = "";
  public readonly tableHeadersParameters= [
    {
      name: "Role",
      orderingKey: "name"
    },
    {
      name: "Number of users",
      orderingKey: "user_count"
    },
    {
      name: "",
      orderingKey: "additional_actions"
    },
  ];
  public pagination: UsersPagination;
  public usersPaginationFactory: UsersPaginationFactory = new UsersPaginationFactory();
  public noSearchingResults: boolean;
  public readonly iconPath = "assets/images/icons/arrow-down.png";
  public readonly userIconSvg = "assets/images/icons/userIcon.svg";
  public static activeClassNameForSorting= "active-sorting";
  private currentOrdering: {[key: string]: SortDirection} = {};
  public isRolesListEmpty = false;

  @ViewChildren("sortingArrow") arrows: QueryList<ElementRef<any>>;
  
  constructor(
    private router: Router,
    public navigationService: NavigationService,
    private whiteLabelConfigurationService: WhiteLabelConfigurationService,
    private db3DApiBackendClient: Db3DApiBackendClient,
    private popupService: PopupService,
    private toastService: ToastService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const queryParamsFromUrl: QueryParamsForGet = {
      page: this.route.snapshot.queryParamMap.get(queryParamsKey.page),
      search: this.route.snapshot.queryParamMap.get(queryParamsKey.search),
      ordering: this.route.snapshot.queryParamMap.get(queryParamsKey.sort),
    };
    this.currentOrdering = this.deserializeQueryParamsToCurrentOrdering(this.route.snapshot.queryParamMap.get(queryParamsKey.sort));
    this.lastSearchQuery = this.route.snapshot.queryParamMap.get(queryParamsKey.search);

    this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.subscribe({
      next: (config) => {
        this.wlSlug = config.slug;
        if(this.wlSlug) this.getRolesList(queryParamsFromUrl);
      },
    });

    queryParamsFromUrl.search && this.searchInputValue.next(queryParamsFromUrl.search);
    this.searchInputValue.pipe(debounceTime(this.usersQuerySearchDelay)).subscribe((inputValue: string) => {
      if(this.wlSlug) this.changeSearchQuery(inputValue);
    });
  }

  ngDoCheck() {
    if(this.route.snapshot.queryParamMap.get(queryParamsKey.sort)) this.addActiveStyleForSortingArrows(this.route.snapshot.queryParamMap.get(queryParamsKey.sort));
  }

  private getRolesList(queryParamsFromUrl: QueryParamsForGet) {
    this.db3DApiBackendClient.getUsersRolesList(environment.db3dBackendDomain, this.wlSlug, queryParamsFromUrl).subscribe({
      next: (userRolesResponse) => {
        this.roles = this.buildUsersRoleList(userRolesResponse.items);
        this.pagination = this.usersPaginationFactory.createFromBackendApi(userRolesResponse.pagination);
        this.addPaginationParamsToUrl(userRolesResponse.pagination.page);
        this.addOrderingParamsToUrl(queryParamsFromUrl);
        this.noSearchingResults = (this.roles.length === 0);
        if(this.roles.length === 0) {
          this.message = UsersDataFetchMessages.emptyList;
          this.isRolesListEmpty = true;
        } else
          this.isRolesListEmpty = false;
      },
      error: (err) => {
        this.message = UsersDataFetchMessages.error;
        if(err.status === 403) this.router.navigate([this.navigationService.pageNotFound]);
        if(err.status === 404) {
          // it handles situation when there is a page with only one role and this role is deleted; backend responses with 404 error, because this page doesn't exist;
          // so we force next request with downgranded number of page in queryParams (ex. if page no.3 doesn't exist, we make next request with page no.2)
          if(this.pagination?.page > 1) {
            const pageNumber = Number(this.route.snapshot.queryParamMap.get(queryParamsKey.page)) - 1;
            const queryParams: QueryParamsForGet = {
              page: pageNumber.toString(),
              search: this.route.snapshot.queryParamMap.get(queryParamsKey.search),
              ordering: this.route.snapshot.queryParamMap.get(queryParamsKey.sort),
            };
            this.getRolesList(queryParams);
          }
        }
      }
    });
  }

  private buildUsersRoleList(rolesFromApi: Array<IUsersRoleApiResponse>): Array<UsersRole> {
    return rolesFromApi.map( (usersRoleJson) => {
      return this.usersRoleFactory.createFromBackendApi(usersRoleJson);
    });
  }

  public goToAddNewRoleView(): void {
    this.router.navigate([this.navigationService.addNewRole]);
  }

  public usersCountIsZero(usersCount: any): boolean {
    return typeof usersCount !== "number" || usersCount > 0;
  }

  public deleteRole(roleSlug: string): void {
    const popupContent = {
      title: "Delete this role?",
      text: ["You will not be able to recover it."],
      buttons: [
        {
          text: "Delete this role",
          callback: () => {
            this.deleteRoleRequest(roleSlug);
            this.popupService.hidePopup();
          }
        }
      ],
      secondaryButtons: [
        {
          text:"Cancel",
          callback: () => {
            this.popupService.hidePopup();
          }
        }
      ]
    };
    this.popupService.showPopup(popupContent);
  }

  public deleteRoleRequest(roleSlug: string): void {
    this.db3DApiBackendClient.deleteRole(environment.db3dBackendDomain, this.wlSlug, roleSlug).subscribe({
      next: (resp) => {
        this.toastService.showToast("Role removed!", ToastType.success);

        const queryParamsFromUrl: QueryParamsForGet = {
          page: this.route.snapshot.queryParamMap.get(queryParamsKey.page),
          search: this.route.snapshot.queryParamMap.get(queryParamsKey.search),
          ordering: this.route.snapshot.queryParamMap.get(queryParamsKey.sort),
        };
        this.getRolesList(queryParamsFromUrl);
      },
      error: (err) => {
        this.toastService.showToast("Role unremoved!", ToastType.error);
        if(err.status === 403) this.router.navigate([this.navigationService.pageNotFound]);
      }
    });
  }

  private addPaginationParamsToUrl(paginationPage: number): void {
    this.router.navigate([], {
      queryParams: {
        page: paginationPage,
      },
      relativeTo: this.route,
      queryParamsHandling: "merge",
      skipLocationChange: false
    });
  }

  private addOrderingParamsToUrl(queryParamsFromUrl: QueryParamsForGet): void {
    let orderingKeys = "";
    if(queryParamsFromUrl.ordering?.includes("name") || queryParamsFromUrl.ordering?.includes("-name"))
      orderingKeys = queryParamsFromUrl.ordering;
    else if(queryParamsFromUrl.ordering === null || queryParamsFromUrl.ordering === undefined)
      orderingKeys = "name";
    else
      orderingKeys = `name,${queryParamsFromUrl.ordering}`;

    this.router.navigate([], {
      queryParams: {
        ordering: orderingKeys,
      },
      relativeTo: this.route,
      queryParamsHandling: "merge",
      skipLocationChange: false
    });
  }

  public changePage(pageNumber: number): any {
    const queryParamsFromUrl: QueryParamsForGet = {
      page: pageNumber.toString(),
      search: this.route.snapshot.queryParamMap.get(queryParamsKey.search),
      ordering: this.route.snapshot.queryParamMap.get(queryParamsKey.sort),
    };
    this.getRolesList(queryParamsFromUrl);
  }

  public changeSearchQuery(inputValue: string): void {
    this.router.navigate([], {
      queryParams: {
        search: inputValue === "" ? null : inputValue
      },
      relativeTo: this.route,
      queryParamsHandling: "merge",
      skipLocationChange: false
    });

    const isSearchQuerySameAsPrevious = this.lastSearchQuery === inputValue;
    const isInputValueNotEmpty = inputValue.length > 0;
    const isLastSearchQueryNotEmpty = this.lastSearchQuery?.length > 0;
    const isInputValueEmpty =  inputValue.length === 0;
    if(( !isSearchQuerySameAsPrevious && isInputValueNotEmpty) || (isLastSearchQueryNotEmpty && isInputValueEmpty)) {
      this.lastSearchQuery = inputValue;
      const queryParamsFromUrl: QueryParamsForGet = {
        page: "1",
        search: inputValue,
        ordering: this.route.snapshot.queryParamMap.get(queryParamsKey.sort),
      };
      this.getRolesList(queryParamsFromUrl);
    } else  {
      const queryParamsFromUrl: QueryParamsForGet = {
        page: this.route.snapshot.queryParamMap.get(queryParamsKey.page),
        search: inputValue,
        ordering: this.route.snapshot.queryParamMap.get(queryParamsKey.sort),
      };
      this.getRolesList(queryParamsFromUrl);
    }
  }

  public sortColumn(direction: SortDirection, sortBy: string): void {
    const oppositeDirection = direction === sortDirection.asc ? sortDirection.desc : sortDirection.asc;
    const clickedSortingArrow = this.findSortingArrow(direction, sortBy);
    const unclickedSortingArrow = this.findSortingArrow(oppositeDirection, sortBy);

    if(unclickedSortingArrow.nativeElement.classList.contains(RolesListComponent.activeClassNameForSorting)) unclickedSortingArrow.nativeElement.classList.remove(RolesListComponent.activeClassNameForSorting);

    if(clickedSortingArrow.nativeElement.classList.contains(RolesListComponent.activeClassNameForSorting))
      clickedSortingArrow.nativeElement.classList.remove(RolesListComponent.activeClassNameForSorting);
    else
      clickedSortingArrow.nativeElement.classList.add(RolesListComponent.activeClassNameForSorting);

    this.updateDisplayedRolesList(direction, sortBy);
  }

  private updateDisplayedRolesList(direction: SortDirection, sortByParam: string): void {
    this.updateCurrentOrdering(direction, sortByParam);

    const newOrderingQueryParams = this.serializeCurrentOrderingToQueryParams(this.currentOrdering);

    this.router.navigate([], {
      queryParams: {
        ordering: newOrderingQueryParams
      },
      relativeTo: this.route,
      queryParamsHandling: "merge",
      skipLocationChange: false
    });

    this.getSortedRolesList(newOrderingQueryParams);
  }

  private serializeCurrentOrderingToQueryParams(currentOrdering: {[key: string]: SortDirection}): string {
    const orderingInQueryParams = [];
    for(const [key, value] of Object.entries(currentOrdering)) {
      if(value === sortDirection.asc)
        orderingInQueryParams.push(key);
      else if(value === sortDirection.desc)
        orderingInQueryParams.push(`${sortDirection.descPrefix}${key}`);
    }

    return orderingInQueryParams.toString();
  }

  private deserializeQueryParamsToCurrentOrdering(queryParams: string): {[key: string]: SortDirection} {
    const ordering: {[key: string]: SortDirection} = {};
    if (queryParams) {
      const params = queryParams.split(",");
      params.map((param: string) => {
        if (param[0] === sortDirection.descPrefix)
          ordering[param.substring(1)] = sortDirection.desc;
        else
          ordering[param] = sortDirection.asc;
      });
    }
    return ordering;
  }

  private updateCurrentOrdering(direction: SortDirection, sortByParam: string): void {
    if(this.currentOrdering[sortByParam] === direction)
      delete this.currentOrdering[sortByParam];
    else
      this.currentOrdering[sortByParam] = direction;
  }

  public getSortedRolesList(newOrderingQuery: string) {
    const queryParams: QueryParamsForGet = {
      page: "1",
      search: this.route.snapshot.queryParamMap.get(queryParamsKey.search),
      ordering: newOrderingQuery,
    };
    this.getRolesList(queryParams);
  }

  public addActiveStyleForSortingArrows(orderingQueryParams: string) {
    const params = orderingQueryParams.split(",");
    params.map((param: string) => {
      if(param[0] === sortDirection.descPrefix) 
        this.findSortingArrow(sortDirection.desc, param.substring(1))?.nativeElement.classList.add(RolesListComponent.activeClassNameForSorting);
      else 
        this.findSortingArrow(sortDirection.asc, param)?.nativeElement.classList.add(RolesListComponent.activeClassNameForSorting);
    });
  }

  public findSortingArrow(direction: string, sortBy: string): ElementRef<any> | undefined {
    if (!this.arrows) return undefined;
    return this.arrows.find((arrow: ElementRef<any>) => arrow.nativeElement.id === `${direction}-${sortBy}`);
  }

}
